import React, { useEffect, useRef, useState } from "react";
import { ChevronDown, X } from "react-feather";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Checkbox } from "semantic-ui-react";

const MultiselectDropdown = ({
  control,
  controlName,
  options,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options || []);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const searchInputRef = useRef(null);

  const { t } = useTranslation();

  const getValues = (checked, values, key) => {
    return checked ? [...values, key] : values.filter((v) => v !== key);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!Array.isArray(options)) {
      console.log("Options is not an array", options);
      return;
    }

    if (typeof searchTerm !== "string") {
      console.log("SearchTerm is not a string", searchTerm);
      return;
    }

    setFilteredOptions(
      options.filter((option) =>
        option.text?.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, options]);

  useEffect(() => {
    if (isOpen) {
      setSearchTerm("");
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    }
  }, [isOpen]);

  return (
    <Controller
      control={control}
      name={controlName}
      render={({ field: { onChange, value: values = [] } }) => {
        const selectedCount = values.length;
        const selectedOption = options.find(
          (option) => option.value === values[0]
        );

        const buttonLabel =
          selectedCount === 1
            ? selectedOption.text
            : selectedCount > 1
            ? `${t("Selected")}: (${selectedCount})`
            : placeholder;

        return (
          <div style={{ position: "relative" }}>
            <button
              ref={buttonRef}
              className="flex items-center px-3 py-2 text-sm leading-5 border border-primse-green rounded-md w-full justify-between"
              type="button"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span>{buttonLabel}</span>
              <ChevronDown color="grey" />
            </button>

            {isOpen && (
              <div
                ref={dropdownRef}
                className="absolute left-0 right-0 z-10 mt-2 p-3 bg-white border border-gray-300 rounded-md shadow-lg"
              >
                <div className="relative">
                  <input
                    ref={searchInputRef}
                    type="text"
                    className="w-full p-2 pr-8 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-300 mb-2"
                    placeholder={t("Search")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {searchTerm && (
                    <button
                      className="absolute right-3 top-1.5 text-gray-500 hover:text-gray-700 hover:bg-gray-200 rounded-full p-1"
                      onClick={() => setSearchTerm("")}
                    >
                      <X size={16} />
                    </button>
                  )}
                </div>
                <ul
                  className="list-none overflow-y-auto mt-2"
                  style={{ maxHeight: "170px" }}
                >
                  {filteredOptions.length > 0 ? (
                    filteredOptions.map((option) => (
                      <li key={option.value} className="mb-1">
                        <Checkbox
                          className="p-2 hover:bg-gray-100 w-full rounded-md"
                          label={option.text}
                          checked={values.includes(option.value)}
                          onChange={(_, e) => {
                            onChange(
                              getValues(e.checked, values, option.value)
                            );
                          }}
                        />
                      </li>
                    ))
                  ) : (
                    <li className="text-gray-500 text-center p-2">
                      {t("No results found")}
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default MultiselectDropdown;
