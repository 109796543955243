import React, { useState, useEffect } from "react";
import { X } from "react-feather";
import { useTranslation } from "react-i18next";
import { Dropdown, Input, Pagination } from "semantic-ui-react";
import { useStoreActions, useStoreState } from "easy-peasy";

import UserServices from "../../services/UserServices";
import { Td } from "../../utils/searchUtils";
import Container from "../../components/Container";

const UserAgencySupervisors = () => {
  const { t } = useTranslation();
  const setFilter = useStoreActions(
    (state) => state.filters.setUserAgencySupervisorsFilter
  );
  const filters = useStoreState(
    (state) => state.filters.userAgencySupervisorsFilter
  );

  const [branchOptions, setBranches] = useState([]);
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [pagination, setPagination] = useState({
    data: [],
    totalItems: 0,
    itemsPerPage: 10,
    pageCount: 1,
  });
  const [activePage, setActivePage] = useState(1);

  useEffect(() => {
    if (filters) {
      setName(filters.full_name || "");
      setEmail(filters.email || "");
      setPhone(filters.phone_number || "");
      setSelectedAgency(filters.branch_id || null);
    }
  }, [filters]);

  useEffect(() => {
    const fetchSupervisors = async () => {
      try {
        const response = await UserServices.getAgencySupervisorsList({
          ...filters,
          page: activePage,
          perPage: pagination.itemsPerPage,
        });

        const branchOptions = Object.entries(
          response?.form?.elements?.branch_id?.options || {}
        ).map(([key, text]) => ({ key, text, value: key }));
        setBranches(branchOptions);

        const totalItems = response?.paginator?.totalItems || 0;
        const agentData = response?.payload || [];

        const pageCount = Math.ceil(totalItems / pagination.itemsPerPage);

        setPagination((prev) => ({
          ...prev,
          totalItems,
          data: agentData,
          pageCount,
        }));
      } catch (error) {
        console.log("Error fetching agency supervisors list: " + error.message);
      }
    };

    fetchSupervisors();
  }, [filters, activePage, pagination.itemsPerPage]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilter({
      email: email || null,
      phone_number: phone || null,
      full_name: name || null,
      branch_id: selectedAgency || null,
    });
  };

  const handleClear = () => {
    setEmail("");
    setPhone("");
    setName("");
    setSelectedAgency(null);
    setFilter({});
    setActivePage(1);
  };

  const handlePageClick = (e, { activePage }) => {
    setActivePage(activePage);
  };

  return (
    <main className="py-4">
      <div>
        <Container className="lg:px-2">
          <header className="sticky top-20 bg-white w-screen z-30 lg:w-full">
            <h1 className="text-2xl font-bold mb-3">
              {t("List of Subordinate Agents")}
            </h1>
            <form
              className="flex flex-1 items-center w-full bg-white flex-col lg:flex-row lg:justify-start lg:py-0 transition-all"
              onSubmit={handleSubmit}
            >
              <Input
                placeholder={t("FistName, LastName")}
                className="w-56 lg:w-40 mx-2 h-12"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Input
                placeholder={t("Phone")}
                className="w-56 lg:w-40 mx-2 h-12"
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <Input
                placeholder={t("E-mail")}
                className="w-56 lg:w-40 mx-2 h-12"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Dropdown
                className="h-12 mx-2"
                placeholder={t("Agency")}
                fluid={false}
                search={true}
                selection={true}
                onChange={(e, data) => setSelectedAgency(data.value)}
                options={branchOptions}
                value={selectedAgency}
                clearable
                selectOnBlur={false}
                noResultsMessage={t("No results found")}
              />
              <div className="mx-2">
                <button
                  className="flex items-center py-3 px-4"
                  onClick={handleClear}
                  type="button"
                >
                  <X size={14} className="mr-2" />
                  {t("Clear")}
                </button>
              </div>
              <div className="mx-2">
                <button
                  className="font-bold text-black uppercase bg-primse-yellow py-3 px-14 rounded-sm transition-all hover:bg-primse-gray hover:text-white"
                  type="submit"
                >
                  {t("Search")}
                </button>
              </div>
            </form>
            <div className="mt-4">
              <h3 className="text-xl font-bold">
                {t("Search results")}:{" "}
                <span className="text-primse-green">
                  {pagination.totalItems}
                </span>
              </h3>
            </div>
          </header>
          <table className="w-full hidden lg:table">
            <thead
              className="bg-white font-bold uppercase text-xs sticky"
              style={{ top: "188px" }}
            >
              <tr className="border-b h-14">
                <th className="px-2 text-left bg-white ">{t("Agent")}</th>
                <th className="px-2 text-left bg-white ">{t("Agency")}</th>
                <th className="px-2 text-left bg-white ">{t("Phone")}</th>
                <th className="px-2 text-left bg-white ">{t("E-mail")}</th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {pagination.data.length > 0 ? (
                pagination.data.map((agent) => (
                  <tr
                    key={agent.user_id}
                    className="border-b h-14 hover:bg-primse-light-green"
                  >
                    <Td>{agent.user_fullname}</Td>
                    <Td>{agent.branch_name}</Td>
                    <Td>{agent.phone_number}</Td>
                    <Td>{agent.user_email}</Td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center py-4">
                    {t("No results found")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {pagination.pageCount > 1 && (
            <div className="py-4">
              <Pagination
                activePage={activePage}
                totalPages={pagination.pageCount}
                onPageChange={handlePageClick}
              />
            </div>
          )}
        </Container>
      </div>
    </main>
  );
};

export default UserAgencySupervisors;
