import axios from "../utils/axios";

const UserServices = {
  getUserProfile: async () => {
    if (localStorage.getItem("token"))
      return await axios.get(`/user-view`).then((res) => res.data.payload);
    else return null;
  },
  getUserForm: async () => {
    return await axios.get(`/user-update`).then((res) => res.data.form);
  },
  updateUserProfile: async (formData) => {
    return await axios.post(`/user-update`, formData).then((res) => res);
  },
  updatePassword: async (formData) => {
    return await axios
      .post(`/user-change-password`, formData)
      .then((res) => res)
      .catch((err) => err);
  },
  resetPasswordSendEmail: async () => {
    return await axios.post(`/user-reset-password`).then((res) => res);
  },

  createPassword: async (formData, hash) => {
    return await axios
      .post(`/user-create-password?hash=${hash}`, formData)
      .then((res) => res)
      .catch((err) => err);
  },

  getAgencySupervisorsList: async (propsParams, page, perPage) => {
    return await axios
      .get("/user-agency-supervisors-list", {
        params: {
          page: page,
          perPage: perPage,
          ...propsParams,
        },
      })
      .then((res) => {
        return res.data;
      });
  },
};

export default UserServices;
