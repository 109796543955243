import React, { useMemo, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Container from "../../components/Container";
import { useTranslation } from "react-i18next";
import {
  Form,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
} from "semantic-ui-react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import TransactionsServices from "../../services/TransactionsServices";
import {
  TransactionCheckbox,
  TransactionDropdown,
  TransactionInput,
} from "../Transactions/components/TransactionInputs";
import Loader from "../../components/Loader";
import BreadcrumbExample from "../../components/Breadcrumb";
import Moment from "react-moment";
import cs from "classnames";
import SingleActionModal from "./SingleActionModal";
import {
  transactionStatusData,
  transactionStatusIcon,
} from "../../utils/statusUtils";
import moment from "moment";

import { useStoreState } from "easy-peasy";
import {
  calculationGrossSum,
  calculationNetSum,
  calculationNetValue,
  convertStringToNumber,
  setTaxFactoring,
} from "./utilsTransaction";
import RealestatesServices from "../../services/RealestatesServices";
import DocumentsServices from "../../services/DocumentsServices";
import { regexStringToNumberPrice } from "../../utils/numbers";
import AddInvoiceModal from "./AddInvoiceModal";
import InvestmentsService from "../../services/InvestmentsService";
// import InvestmentsService from "../../services/InvestmentsService";

const Transaction = () => {
  const [data, setData] = useState(null);
  const location = useLocation();
  const [firstDataQueryLoad, setFirstDataQueryLoad] = useState(true);
  const [enabledQueryTransaction, setEnableQueryTransaction] = useState(false);
  const [uuidQueryFlats, setUuidQueryFlats] = useState(false);
  const [enabledQueryFlats, setEnabledQueryFlats] = useState(false);
  // const [historyElements, setHistoryElements] = useState(null);

  const [loadedDataQuery, setLoadedDataQuery] = useState(false);

  const { uuid } = useParams();

  const { data: dataQuery, isLoading } = useQuery(
    `transaction-${uuid}`,
    TransactionsServices.getTransactionDetails(uuid),
    { enabled: enabledQueryTransaction && firstDataQueryLoad }
  );

  // const { data: dataHistory } = useQuery(
  //   `history-transaction-${uuid}`,
  //   TransactionsServices.getTransactionHistory(uuid),
  //   { enabled: enabledQueryTransaction }
  // );

  const { data: flatList } = useQuery(
    `flats-transaction-${uuidQueryFlats}`,
    RealestatesServices.getFlatList(uuidQueryFlats, { perPage: 999 }, true),
    { enabled: enabledQueryFlats }
  );
  const {
    data: investmentTerm,
  } = useQuery(
    `investmetnt-term-transation-${data?.investment_uuid}`,
    InvestmentsService.getInvestmentTerms(data?.investment_uuid),
    { enabled: data?.investment_uuid ? true : false }
  );
  const history = useHistory();
  const { t } = useTranslation();
  const { control, watch, setValue } = useForm();

  const getWatch = () => {
    return watch();
  };

  const handleRefresh = () => {
    TransactionsServices.getTransactionDetails(uuid)().then(() => {
      setEnableQueryTransaction(true);
    });
  };

  const handleTransactionPriceGrossSum = (name) => {
    calculationGrossSum(control, name, setValue);
    calculationNetValue(control, name, setValue, taxType);
    calculationNetSum(control, name, setValue);
  };

  const handleCheckbox = (name) => {
    calculationGrossSum(control, name, setValue);
    calculationNetSum(control, name, setValue);
  };
  const handleTax = (name) => {
    calculationNetValue(control, name, setValue, taxType);
    calculationNetSum(control, name, setValue);
  };

  const taxFactoring = (value) => {
    setTaxFactoring(
      value,
      setValue,
      data?.commission_agency_percentage,
      data?.agreement_factoring_commission,
      control
    );
  };

  useEffect(() => {
    if (dataQuery) {
      if (
        dataQuery?.client_reservation_agreement_at &&
        dataQuery?.status_id === "1"
      ) {
        setLoadedDataQuery(true);
      }
      setData(dataQuery);
      setValue("BO_agreement_commission", dataQuery?.agreement_commission);
      setValue(
        "BO_agreement_factoring_commission",
        dataQuery?.agreement_factoring_commission
      );

      if (dataQuery?.status_id) {
        setValue("status_id", dataQuery?.status_id);
      }
      if (dataQuery?.investment_uuid) {
        setUuidQueryFlats(dataQuery?.investment_uuid);
        setEnabledQueryFlats(true);
      }
      setEnableQueryTransaction(false);
      setFirstDataQueryLoad(false);
    }
  }, [dataQuery]);

  useEffect(() => {
    if (location.pathname !== "/transaction/create") {
      setEnableQueryTransaction(true);
    } else {
      if (location?.state) {
        for (const [key, value] of Object.entries(location?.state?.data)) {
          if (
            key === "parking_price_gross" ||
            key === "parking_extra_1_price_gross" ||
            key === "parking_extra_2_price_gross" ||
            key === "storage_price_gross" ||
            key === "storage_extra_1_price_gross" ||
            key === "storage_extra_2_price_gross"
          ) {
            setValue(key, regexStringToNumberPrice(value));
          } else {
            setValue(key, value);
          }
        }
        setData(location?.state?.data);
        handleTax("parking_price_gross");
        handleTax("parking_extra_1_price_gross");
        handleTax("parking_extra_2_price_gross");
        handleTax("storage_price_gross");
        handleTax("storage_extra_1_price_gross");
        handleTax("storage_extra_2_price_gross");

        setValue("realestate_is_paid", true);
        setUuidQueryFlats(location?.state?.data?.investment_uuid);
        setEnabledQueryFlats(true);
        setFirstDataQueryLoad(false);
      } else {
        history.push("/transactions");
      }
    }
  }, [location.pathname]);

  const [realestateList, setRealestateList] = useState([]);

  const userRole = useStoreState((state) => state.auth.user?.role);
  const userPrimaryRole = useStoreState(
    (state) => state.auth.user?.primaryRole
  );

  const userPermmisions = (type) => {
    if (userPrimaryRole === "Admin") return true;
    if (userPrimaryRole === "Top Admin") return true;
    if (type === "Agency" && userRole === "Agency") return true;
    else if (type === "Developer" && userRole === "Deweloper") return true;
    else return false;
  };

  useEffect(() => {
    if (flatList) {
      const arr = [];

      flatList?.payload?.forEach(({ id, name, price, tax_type_id }) =>
        arr.push({
          value: id,
          text: name,
          price: price,
          tax_type_id: tax_type_id || "5",
        })
      );
      if (!dataQuery?.realestate_id && dataQuery?.realestate_name) {
        arr.push({
          value: "other",
          text: dataQuery?.realestate_name,
          price: 0,
          tax_type_id: dataQuery?.tax_type_id,
        });
      }
      if (dataQuery?.realestate_id && dataQuery?.realestate_name) {
        const exists = arr.find(
          (item) => item.value === dataQuery.realestate_id
        );
        if (!exists) {
          arr.push({
            value: dataQuery.realestate_id,
            text: dataQuery.realestate_name,
            price: dataQuery?.realestate_price_gross,
            tax_type_id: dataQuery?.realestate_tax_type_id || "5",
          });
        }
      }
      setRealestateList(arr);
    }
  }, [flatList]);

  const disableFactoring = useMemo(() => {
    if (!data?.status_id) return false;
    if (data?.status_id === "1") return false;

    return true;
  }, [data?.status_id]);

  const BREADCRUMB_DATA = useMemo(() => {
    return [
      {
        name: "Main page",
        linkPage: "/",
      },
      {
        name: `Transactions`,
        linkPage: `transactions`,
      },
    ];
  }, []);

  const factoringOptions = useMemo(() => {
    return [
      { value: "0", text: t("No") },
      { value: "1", text: t("Yes") },
    ];
  }, [t]);

  const taxType = useMemo(() => {
    return [
      { value: "1", text: "VAT 23%", tax: 123 },
      { value: "2", text: "VAT 8%", tax: 108 },
      { value: "3", text: t("Without VAT"), tax: 100 },
      { value: "4", text: "VAT 0", tax: 100 },
      { value: "5", text: "VAT 8/23", tax: 123 },
    ];
  }, [t]);

  const disabledFields = useMemo(() => {
    if (!data) return true;
    if (data?.status_id === "1" || !data?.status_id) return false;
    return true;
  }, [data]);

  const disabledFieldsTax = useMemo(() => {
    if (!data) return true;
    if (!userPermmisions("Developer") && !data?.status_id) return false;
    if (!userPermmisions("Developer") && data?.status_id === "1") return false;
    return true;
  }, [data]);

  const commissionPromotionalFunction = () => {
    if (investmentTerm) {
      const dateNow = new Date(watch()?.client_reservation_agreement_at);

      Date.prototype.addHours = function (h) {
        this.setTime(this.getTime() + h * 60 * 60 * 1000);
        return this;
      };

      let fixed_commission_index = null;
      let promotional_commission_index = null;
      investmentTerm?.commision?.forEach((el, index) => {
        if (el?.fixed_commission === "0") {
          promotional_commission_index = index;
        }
        if (el?.fixed_commission === "1") {
          fixed_commission_index = index;
        }
      });

      let index = fixed_commission_index;

      const commision =
        investmentTerm?.commision[promotional_commission_index]?.commision;
      const commission_promotional_valid_from =
        investmentTerm?.commision[promotional_commission_index]
          ?.commission_promotional_valid_from;
      const commission_promotional_valid_to =
        investmentTerm?.commision[promotional_commission_index]
          ?.commission_promotional_valid_to;

      if (
        commision &&
        commission_promotional_valid_from &&
        commission_promotional_valid_to &&
        new Date(commission_promotional_valid_from) < dateNow &&
        new Date(commission_promotional_valid_to).addHours(24) > dateNow
      ) {
        index = promotional_commission_index;
      }
      if (
        commision &&
        commission_promotional_valid_from &&
        new Date(commission_promotional_valid_from) < dateNow &&
        !commission_promotional_valid_to
      ) {
        index = promotional_commission_index;
      }
      if (
        commision &&
        commission_promotional_valid_to &&
        new Date(commission_promotional_valid_to).addHours(24) > dateNow &&
        !commission_promotional_valid_from
      ) {
        index = promotional_commission_index;
      }

      let priceText = "";

      if (
        watch()?.commission_has_factoring === "1" &&
        investmentTerm?.commision[index]?.factoring_commision
      ) {
        priceText += investmentTerm?.commision[index]?.factoring_commision;
      }
      if (
        watch()?.commission_has_factoring === "0" &&
        investmentTerm?.commision[index]?.commision
      ) {
        priceText += investmentTerm?.commision[index]?.commision;
      }

      const formatTimeZoneDate = (date) => {
        const tzoffset = new Date(date).getTimezoneOffset() * 60000; //offset in milliseconds

        const localISOTime = new Date(
          new Date(date) - tzoffset
        ).toLocaleDateString("pt-PT");

        return localISOTime;
      };

      const dateObj = { from: null, to: null };
      if (
        investmentTerm?.commision?.length > 1 &&
        index === promotional_commission_index
      ) {
        if (commission_promotional_valid_from)
          dateObj.from = formatTimeZoneDate(
            commission_promotional_valid_from
          ).replaceAll("/", ".");
        if (commission_promotional_valid_to)
          dateObj.to = formatTimeZoneDate(
            commission_promotional_valid_to
          ).replaceAll("/", ".");
      }

      let promotional = false;

      if (index === promotional_commission_index && !disabledFields) {
        if (
          new Date(
            investmentTerm?.commision[index]?.commission_promotional_valid_from
          ) <= new Date(watch()?.client_reservation_agreement_at) &&
          new Date(
            investmentTerm?.commision[index]?.commission_promotional_valid_to
          ) >= new Date(watch()?.client_reservation_agreement_at).addHours(-2)
        ) {
          promotional = true;
        }
        if (
          new Date(
            investmentTerm?.commision[index]?.commission_promotional_valid_from
          ) <= new Date(watch()?.client_reservation_agreement_at) &&
          !investmentTerm?.commision[index]?.commission_promotional_valid_to
        ) {
          promotional = true;
        }
      }

      setValue(
        "commission_agency_percentage",
        watch()?.commission_has_factoring === "1"
          ? investmentTerm?.commision[index]?.factoring_commision
          : investmentTerm?.commision[index]?.commision
      );
      setValue(
        "agreement_factoring_commission",
        investmentTerm?.commision[index]?.factoring_commision
      );

      setData((prev) => {
        return {
          ...prev,
          commission_agency_percentage:
            investmentTerm?.commision[index]?.commision,
          agreement_factoring_commission:
            investmentTerm?.commision[index]?.factoring_commision,
        };
      });

      const priceTotalNet = watch()?.total_price_net
        ? convertStringToNumber(watch()?.total_price_net)
        : 0;

      if (watch()?.commission_has_factoring === "1") {
        setValue(
          "commission_agency_percentage",
          investmentTerm?.commision[index]?.factoring_commision
        );
        setValue(
          "commission_agency_price_net",
          `${regexStringToNumberPrice(
            (investmentTerm?.commision[index]?.factoring_commision *
              1 *
              priceTotalNet) /
              100
          )}`
        );
      } else {
        setValue(
          "commission_agency_percentage",
          investmentTerm?.commision[index]?.commision
        );
        setValue(
          "commission_agency_price_net",
          `${regexStringToNumberPrice(
            (investmentTerm?.commision[index]?.commision * 1 * priceTotalNet) /
              100
          )}`
        );
      }

      setValue(
        "commission_agency_price_net",
        `${regexStringToNumberPrice(
          (priceText * 1 * convertStringToNumber(watch()?.total_price_net)) /
            100
        )}`
      );

      const obj = {
        promotional,
        from: dateObj?.from,
        to: dateObj?.to,
        priceText,
      };
      return obj;
    } else return null;
  };

  const commissionPromotional = useMemo(() => {
    if (disabledFields) return;
    if (investmentTerm && !firstDataQueryLoad) {
      return commissionPromotionalFunction();
    } else return null;
  }, [watch().client_reservation_agreement_at, disabledFields]);

  useEffect(() => {
    if (disabledFields) return;
    if (investmentTerm && loadedDataQuery) {
      commissionPromotionalFunction();

      setLoadedDataQuery(false);
    }
  }, [investmentTerm, disabledFields]);

  useEffect(() => {
    if (disabledFields) return;
    if (watch().total_price_net && watch().client_reservation_agreement_at) {
      setTimeout(() => {
        const watchForm = control.fieldsRef.current;
        const priceTotalNet = watchForm?.total_price_net?._f?.value
          ? convertStringToNumber(watchForm?.total_price_net?._f?.value)
          : 0;

        if (watch().commission_has_factoring === "1") {
          setValue(
            "commission_agency_price_net",
            `${regexStringToNumberPrice(
              (data?.agreement_factoring_commission * 1 * priceTotalNet) / 100
            )}`
          );
        } else {
          setValue(
            "commission_agency_price_net",
            `${regexStringToNumberPrice(
              (data?.commission_agency_percentage * 1 * priceTotalNet) / 100
            )}`
          );
        }
      }, 200);

      // taxFactoring();
    }
  }, [
    watch().total_price_net,
    watch().client_reservation_agreement_at,
    watch()?.commission_agency_percentage,
    disabledFields,
  ]);

  const getInvoicesStatuses = useMemo(() => {
    if (!data) return { agent: null, developer: null };
    const getStatusName = (id) => {
      switch (id) {
        case "1":
          return t("To be issued");
        case "2":
          return t("To be paid");
        case "3":
          return t("Paid");
        case "4":
          return t("Deleted");

        default:
          return null;
      }
    };
    return {
      agent: getStatusName(data?.invoice_status_id),
      developer: getStatusName(data?.developer_invoice_status_id),
    };
  }, [t, data]);

  return (
    <main className="py-0 mt-4">
      <Container style={{ minHeight: "calc(80vh + 1rem)" }}>
        {!isLoading && data && (
          <>
            <BreadcrumbExample
              pages={BREADCRUMB_DATA}
              returnPage={"Transactions"}
              current={data?.id ? data?.id : "Brak ID"}
            />

            <Form>
              <header className="border items-center justify-between flex">
                <div className="w-full flex flex-col lg:flex-row lg:justify-between">
                  <div className="pt-2 px-2 pb-2 flex  justify-between w-full items-center">
                    <div className="mx-2 flex flex-col md:flex-row items-start md:items-center justify-between">
                      <div className="my-2 md:my-0 mx-4 flex justify-between items-center">
                        {t("Creation date")}:{" "}
                        <span className="font-bold mx-2">
                          <Moment format="YYYY-MM-DD, HH:mm:ss">
                            {data?.created_at}
                          </Moment>
                        </span>
                      </div>{" "}
                      {enabledQueryTransaction && (
                        <div className="my-2 md:my-0 mx-4 flex items-center">
                          {t("Transaction status")}:
                          <div className="my-2 md:my-0 ml-2 flex items-center">
                            {transactionStatusIcon(data?.status_id)}
                            <div
                              className={cs(
                                "font-bold mx-2 flex flex-col",
                                transactionStatusData(data?.status_id, "color")
                              )}
                            >
                              <span>{`${transactionStatusData(
                                data?.status_id
                              )} ${t("from")}`}</span>
                              <Moment
                                format="YYYY-MM-DD, HH:mm:ss"
                                className="whitespace-nowrap"
                              >
                                {data?.modified_at}
                              </Moment>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {userPermmisions("Agency") && !data?.status_id && (
                    <div className="flex justify-between lg:justify-end lg:items-center mx-6">
                      <div className="my-2">
                        <SingleActionModal
                          accept={true}
                          watchForm={getWatch}
                          buttonName={t("Save without sending")}
                          routerPush={false}
                          text={t(
                            "Are you sure you want to save without sending?"
                          )}
                          create
                          requestUuid={data?.id}
                        />
                      </div>
                      <div className="my-2">
                        <SingleActionModal
                          accept={true}
                          watchForm={getWatch}
                          buttonName={t("Send for billing")}
                          text={t("Are you sure you want to send for billing?")}
                          create
                          nextStatus={1}
                          requestUuid={data?.id}
                        />
                      </div>
                      <div className="my-2">
                        <SingleActionModal
                          accept={false}
                          requestUuid={uuid}
                          watchForm={getWatch}
                          // handleRefresh={handleRefresh}
                          buttonName={"Cancel"}
                          text={t("Are you sure you want to cancel?")}
                          cancel
                        />
                      </div>
                    </div>
                  )}
                  {userPermmisions("Agency") &&
                    (data?.status_id === "1" || data?.status_id === "8") && (
                      <div className="flex justify-between lg:justify-end lg:items-center mx-6">
                        <div className="my-2">
                          <SingleActionModal
                            accept={true}
                            transactionUuid={uuid}
                            watchForm={getWatch}
                            // handleRefresh={handleRefresh}
                            buttonName={t("Save without sending")}
                            routerPush={false}
                            text={t(
                              "Are you sure you want to save without sending?"
                            )}
                          />
                        </div>
                        <div className="my-2">
                          <SingleActionModal
                            accept={true}
                            transactionUuid={uuid}
                            watchForm={getWatch}
                            // handleRefresh={handleRefresh}
                            buttonName={t("Send for billing")}
                            nextStatus={1}
                            text={t(
                              "Are you sure you want to send for billing?"
                            )}
                          />
                        </div>
                        <div className="my-2">
                          <SingleActionModal
                            accept={false}
                            requestUuid={uuid}
                            watchForm={getWatch}
                            // handleRefresh={handleRefresh}
                            text={t("Are you sure you want to cancel?")}
                            cancel
                            buttonName={t("Cancel")}
                          />
                        </div>
                      </div>
                    )}

                  {userPermmisions("Developer") && data?.status_id === "3" && (
                    <div className="flex justify-between lg:justify-end lg:items-center mx-6">
                      <div className="my-2">
                        <SingleActionModal
                          accept={true}
                          transactionUuid={uuid}
                          watchForm={getWatch}
                          // handleRefresh={handleRefresh}
                          buttonName={t("Save as is")}
                          routerPush={false}
                          text={t(
                            "Are you sure you want to save without changing?"
                          )}
                        />
                      </div>
                      <div className="my-2">
                        <SingleActionModal
                          accept={true}
                          transactionUuid={uuid}
                          watchForm={getWatch}
                          // handleRefresh={handleRefresh}
                          buttonName={t("Accept2")}
                          nextStatus={2}
                          text={t("Are you sure you want to accept?")}
                        />
                      </div>
                      <div className="my-2">
                        <SingleActionModal
                          accept={false}
                          transactionUuid={uuid}
                          watchForm={getWatch}
                          // handleRefresh={handleRefresh}
                          text={t("Are you sure you want to decline?")}
                          discard={1}
                        />
                      </div>
                    </div>
                  )}
                  {userPermmisions("Developer") && data?.status_id === "6" && (
                    <div className="flex justify-between lg:justify-end lg:items-center mx-6">
                      <div className="my-2">
                        <SingleActionModal
                          accept={true}
                          transactionUuid={uuid}
                          watchForm={getWatch}
                          // handleRefresh={handleRefresh}
                          buttonName={t("Accept2")}
                          nextStatus={1}
                          text={t("Are you sure you want to accept?")}
                        />
                      </div>
                      <div className="my-2">
                        <SingleActionModal
                          accept={false}
                          transactionUuid={uuid}
                          watchForm={getWatch}
                          // handleRefresh={handleRefresh}
                          text={t("Are you sure you want to cancel?")}
                          cancel
                          buttonName={"Cancel"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </header>
              <main className="mt-t pb-5">
                <div className="border">
                  <div className="flex justify-around my-2 border-b-2 py-2">
                    <section className="flex flex-col w-2/5">
                      <h3 className="text-xl font-bold">{t("Client")}</h3>
                      <TransactionInput
                        disabled={true}
                        bold
                        defaultValue={data?.client_last_name}
                        control={control}
                        name="client_last_name"
                        type="text"
                        label="Surname"
                        className="w-7/12"
                        classNameLabel="w-1/2"
                      />
                      <TransactionInput
                        disabled={true}
                        defaultValue={data?.client_first_name}
                        control={control}
                        name="client_first_name"
                        type="text"
                        label="Client name"
                        className="w-7/12"
                        classNameLabel="w-1/2"
                      />
                      <TransactionInput
                        disabled={true}
                        defaultValue={data?.client_phone}
                        control={control}
                        name="client_phone"
                        type="text"
                        label="Phone"
                        className="w-7/12"
                        classNameLabel="w-1/2"
                      />
                      <TransactionInput
                        disabled={true}
                        defaultValue={data?.client_email}
                        control={control}
                        name="client_email"
                        type="text"
                        label="E-mail"
                        className="w-7/12"
                        classNameLabel="w-1/2"
                      />
                      <TransactionInput
                        disabled={disabledFields}
                        control={control}
                        name="client_agreement_at"
                        defaultValue={
                          data?.client_agreement_at
                            ? moment(data.client_agreement_at).format(
                                "YYYY-MM-DD"
                              )
                            : null
                        }
                        type="date"
                        label="Sell date"
                        className="w-7/12"
                        classNameLabel="w-1/2"
                      />
                      <TransactionInput
                        disabled={disabledFields}
                        control={control}
                        name="client_reservation_agreement_at"
                        defaultValue={
                          data?.client_reservation_agreement_at
                            ? moment(
                                data.client_reservation_agreement_at
                              ).format("YYYY-MM-DD")
                            : null
                        }
                        type="date"
                        label="Reservation agreement date"
                        className="w-7/12"
                        itemsCenter
                        classNameLabel="w-1/2"
                        required
                      />
                    </section>
                    <section className="flex flex-col w-1/2">
                      <h3 className="text-xl font-bold">{t("Real estate")}</h3>
                      <TransactionInput
                        disabled={true}
                        defaultValue={data?.developer_name}
                        control={control}
                        name="developer_name"
                        type="text"
                        label="Developer"
                      />
                      <TransactionInput
                        disabled={true}
                        defaultValue={data?.investment_name}
                        control={control}
                        name="investment_name"
                        type="text"
                        label="Investment"
                      />
                      <TransactionDropdown
                        disabled={disabledFields}
                        control={control}
                        name="realestate_id"
                        defaultValue={
                          data?.realestate_id
                            ? data?.realestate_id
                            : data?.realestate_name
                            ? "other"
                            : null
                        }
                        options={realestateList}
                        label="Apartment"
                        allowAdditionsProps
                        handleTax={(e, name, data) => {
                          const filter = data?.options?.filter(
                            (el) => el.value === name
                          );

                          if (filter?.length > 0) {
                            setValue(
                              "realestate_price_gross",
                              regexStringToNumberPrice(filter[0]?.price)
                            );

                            setValue("realestate_name", filter[0]?.text);

                            setValue(
                              "realestate_tax_type_id",
                              `${filter[0]?.tax_type_id}`
                            );
                            handleTransactionPriceGrossSum(
                              "realestate_price_gross"
                            );
                          } else {
                            setValue("realestate_name", name);
                            setValue("realestate_id", "other");
                            setRealestateList((prev) => [
                              ...prev.filter((el) => el.value !== "other"),
                              {
                                value: "other",
                                text: name,
                                tax_type_id: "1",
                              },
                            ]);
                          }
                        }}
                      />
                    </section>
                  </div>
                  {/* transaction */}
                  <div className="justify-around my-2 border-b-2 py-2">
                    <section
                      className="flex flex-col w-1/2"
                      style={{ marginLeft: "40px" }}
                    >
                      <h3 className="text-xl font-bold">{t("Transaction")}</h3>
                      <Table celled structured textAlign="center" size="small">
                        <TableHeader>
                          <TableRow>
                            <Table.HeaderCell
                              style={{
                                textTransform: "uppercase",
                                fontSize: "0.9em",
                              }}
                            ></Table.HeaderCell>

                            <Table.HeaderCell
                              style={{
                                textTransform: "uppercase",
                                fontSize: "0.9em",
                              }}
                            >
                              {t("Is paid")}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{
                                textTransform: "uppercase",
                                fontSize: "0.9em",
                              }}
                            >
                              {t("Price gross")}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{
                                textTransform: "uppercase",
                                fontSize: "0.9em",
                              }}
                            >
                              {t("Tax")}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{
                                textTransform: "uppercase",
                                fontSize: "0.9em",
                              }}
                            >
                              {t("Price net")}
                            </Table.HeaderCell>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          <TableRow>
                            <TableCell>{t("Apartment")}</TableCell>
                            <TableCell>
                              <TransactionCheckbox
                                disabled={disabledFields}
                                handleCheckbox={handleCheckbox}
                                defaultValue={data?.realestate_is_paid}
                                control={control}
                                name="realestate_is_paid"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                disabled={disabledFields}
                                amount
                                handleAmount={handleTransactionPriceGrossSum}
                                defaultValue={regexStringToNumberPrice(
                                  data?.realestate_price_gross
                                )}
                                control={control}
                                name="realestate_price_gross"
                                type="text"
                                label=""
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionDropdown
                                defaultValue={
                                  data?.realestate_tax_type_id
                                    ? data?.realestate_tax_type_id
                                    : taxType[0].value
                                }
                                control={control}
                                disabled={disabledFieldsTax}
                                name="realestate_tax_type_id"
                                size="small"
                                options={taxType}
                                handleTax={handleTax}
                                titleTax="realestate_price_gross"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                amount
                                defaultValue={regexStringToNumberPrice(
                                  data?.realestate_price_net
                                )}
                                textAlign="right"
                                control={control}
                                name="realestate_price_net"
                                type="text"
                                disabled={true}
                                label=""
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Parking space")}</TableCell>
                            <TableCell>
                              <TransactionCheckbox
                                disabled={disabledFields}
                                handleCheckbox={handleCheckbox}
                                defaultValue={data?.parking_is_paid}
                                control={control}
                                name="parking_is_paid"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                amount
                                disabled={disabledFields}
                                handleAmount={handleTransactionPriceGrossSum}
                                defaultValue={
                                  data?.parking_price_gross ||
                                  data?.parking_space_price
                                    ? regexStringToNumberPrice(
                                        data?.parking_price_gross ||
                                          data?.parking_space_price
                                      )
                                    : "0.00"
                                }
                                control={control}
                                name="parking_price_gross"
                                type="text"
                                label=""
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionDropdown
                                defaultValue={
                                  data?.parking_tax_type_id
                                    ? data?.parking_tax_type_id
                                    : taxType[0].value
                                }
                                control={control}
                                name="parking_tax_type_id"
                                size="small"
                                disabled={disabledFields}
                                options={taxType}
                                handleTax={handleTax}
                                titleTax="parking_price_gross"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                amount
                                disabled={true}
                                defaultValue={regexStringToNumberPrice(
                                  data?.parking_price_net
                                )}
                                control={control}
                                name="parking_price_net"
                                type="text"
                                label=""
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Parking space")} 1</TableCell>
                            <TableCell>
                              <TransactionCheckbox
                                disabled={disabledFields}
                                handleCheckbox={handleCheckbox}
                                defaultValue={data?.parking_extra_1_is_paid}
                                control={control}
                                name="parking_extra_1_is_paid"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                disabled={disabledFields}
                                amount
                                handleAmount={handleTransactionPriceGrossSum}
                                defaultValue={
                                  data?.parking_extra_1_price_gross
                                    ? parseFloat(
                                        data?.parking_extra_1_price_gross
                                      ).toFixed(2)
                                    : "0.00"
                                }
                                control={control}
                                name="parking_extra_1_price_gross"
                                type="text"
                                label=""
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionDropdown
                                defaultValue={
                                  data?.parking_extra_1_tax_type_id
                                    ? data?.parking_extra_1_tax_type_id
                                    : taxType[0].value
                                }
                                control={control}
                                name="parking_extra_1_tax_type_id"
                                size="small"
                                options={taxType}
                                handleTax={handleTax}
                                disabled={disabledFields}
                                titleTax="parking_extra_1_price_gross"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                amount
                                disabled={true}
                                defaultValue={regexStringToNumberPrice(
                                  data?.parking_extra_1_price_net
                                )}
                                control={control}
                                name="parking_extra_1_price_net"
                                type="text"
                                label=""
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Parking space")} 2</TableCell>
                            <TableCell>
                              <TransactionCheckbox
                                disabled={disabledFields}
                                handleCheckbox={handleCheckbox}
                                defaultValue={data?.parking_extra_2_is_paid}
                                control={control}
                                name="parking_extra_2_is_paid"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                disabled={disabledFields}
                                amount
                                handleAmount={handleTransactionPriceGrossSum}
                                defaultValue={
                                  data?.parking_extra_2_price_gross
                                    ? parseFloat(
                                        data?.parking_extra_2_price_gross
                                      ).toFixed(2)
                                    : "0.00"
                                }
                                control={control}
                                name="parking_extra_2_price_gross"
                                type="text"
                                label=""
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionDropdown
                                defaultValue={
                                  data?.parking_extra_2_tax_type_id
                                    ? data?.parking_extra_2_tax_type_id
                                    : taxType[0].value
                                }
                                control={control}
                                name="parking_extra_2_tax_type_id"
                                size="small"
                                options={taxType}
                                handleTax={handleTax}
                                disabled={disabledFields}
                                titleTax="parking_extra_2_price_gross"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                amount
                                disabled={true}
                                defaultValue={regexStringToNumberPrice(
                                  data?.parking_extra_2_price_net
                                )}
                                control={control}
                                name="parking_extra_2_price_net"
                                type="text"
                                label=""
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Storage room")}</TableCell>
                            <TableCell>
                              <TransactionCheckbox
                                disabled={disabledFields}
                                handleCheckbox={handleCheckbox}
                                defaultValue={data?.storage_is_paid}
                                control={control}
                                name="storage_is_paid"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                disabled={disabledFields}
                                amount
                                handleAmount={handleTransactionPriceGrossSum}
                                defaultValue={
                                  data?.storage_price_gross
                                    ? parseFloat(
                                        data?.storage_price_gross
                                      ).toFixed(2)
                                    : "0.00"
                                }
                                control={control}
                                name="storage_price_gross"
                                type="text"
                                label=""
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionDropdown
                                defaultValue={
                                  data?.storage_tax_type_id
                                    ? data?.storage_tax_type_id
                                    : taxType[0].value
                                }
                                control={control}
                                name="storage_tax_type_id"
                                size="small"
                                options={taxType}
                                handleTax={handleTax}
                                disabled={disabledFields}
                                titleTax="storage_price_gross"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                amount
                                disabled={true}
                                defaultValue={regexStringToNumberPrice(
                                  data?.storage_price_net
                                )}
                                control={control}
                                name="storage_price_net"
                                type="text"
                                label=""
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Storage room")} 1</TableCell>
                            <TableCell>
                              <TransactionCheckbox
                                disabled={disabledFields}
                                handleCheckbox={handleCheckbox}
                                defaultValue={data?.storage_extra_1_is_paid}
                                control={control}
                                name="storage_extra_1_is_paid"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                disabled={disabledFields}
                                amount
                                defaultValue={
                                  data?.storage_extra_1_price_gross
                                    ? parseFloat(
                                        data?.storage_extra_1_price_gross
                                      ).toFixed(2)
                                    : "0.00"
                                }
                                handleAmount={handleTransactionPriceGrossSum}
                                control={control}
                                name="storage_extra_1_price_gross"
                                type="text"
                                label=""
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionDropdown
                                defaultValue={
                                  data?.storage_extra_1_tax_type_id
                                    ? data?.storage_extra_1_tax_type_id
                                    : taxType[0].value
                                }
                                control={control}
                                name="storage_extra_1_tax_type_id"
                                size="small"
                                options={taxType}
                                handleTax={handleTax}
                                disabled={disabledFields}
                                titleTax="storage_extra_1_price_gross"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                amount
                                disabled={true}
                                defaultValue={regexStringToNumberPrice(
                                  data?.storage_extra_1_price_net
                                )}
                                control={control}
                                name="storage_extra_1_price_net"
                                type="text"
                                label=""
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t("Storage room")} 2</TableCell>
                            <TableCell>
                              <TransactionCheckbox
                                disabled={disabledFields}
                                handleCheckbox={handleCheckbox}
                                defaultValue={data?.storage_extra_2_is_paid}
                                control={control}
                                name="storage_extra_2_is_paid"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                disabled={disabledFields}
                                amount
                                handleAmount={handleTransactionPriceGrossSum}
                                defaultValue={
                                  data?.storage_extra_2_price_gross
                                    ? parseFloat(
                                        data?.storage_extra_2_price_gross
                                      ).toFixed(2)
                                    : "0.00"
                                }
                                control={control}
                                name="storage_extra_2_price_gross"
                                type="text"
                                label=""
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionDropdown
                                defaultValue={
                                  data?.storage_extra_2_tax_type_id
                                    ? data?.storage_extra_2_tax_type_id
                                    : taxType[0].value
                                }
                                control={control}
                                name="storage_extra_2_tax_type_id"
                                size="small"
                                options={taxType}
                                handleTax={handleTax}
                                disabled={disabledFields}
                                titleTax="storage_extra_2_price_gross"
                              />
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                amount
                                disabled={true}
                                defaultValue={regexStringToNumberPrice(
                                  data?.storage_extra_2_price_net
                                )}
                                control={control}
                                name="storage_extra_2_price_net"
                                type="text"
                                label=""
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{t("Total")}</TableCell>
                            <TableCell>
                              <TransactionInput
                                amount
                                disabled={true}
                                defaultValue={
                                  data?.total_price_gross
                                    ? parseFloat(
                                        data?.total_price_gross
                                      ).toFixed(2)
                                    : "0.00"
                                }
                                control={control}
                                name="total_price_gross"
                                type="text"
                                label=""
                              />
                            </TableCell>
                            <TableCell textAlign="right">
                              {t("Total")}
                            </TableCell>
                            <TableCell>
                              <TransactionInput
                                amount
                                disabled={true}
                                defaultValue={regexStringToNumberPrice(
                                  data?.total_price_net
                                )}
                                control={control}
                                name="total_price_net"
                                type="text"
                                label=""
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </section>
                  </div>
                  {/* prowizje */}
                  <div className="justify-around my-2 border-b-2 py-2">
                    <section
                      className="flex flex-col w-1/2"
                      style={{ marginLeft: "40px" }}
                    >
                      <h3 className="text-xl font-bold">{t("Commission")}</h3>
                      {data?.agreement_factoring &&
                        data?.agreement_factoring === "1" && (
                          <div className="flex  items-center">
                            <p className="pr-2 mb-0">Factoring:</p>

                            <TransactionDropdown
                              disabled={disableFactoring && disabledFields}
                              control={control}
                              options={factoringOptions}
                              name="commission_has_factoring"
                              defaultValue={
                                data?.commission_has_factoring
                                  ? data?.commission_has_factoring === "1"
                                    ? "1"
                                    : "0"
                                  : "0"
                              }
                              taxFactoring={taxFactoring}
                            />
                          </div>
                        )}
                      <Table celled structured textAlign="center" size="small">
                        <TableHeader>
                          <TableRow>
                            <Table.HeaderCell
                              style={{
                                textTransform: "uppercase",
                                fontSize: "0.9em",
                              }}
                            ></Table.HeaderCell>
                            <Table.HeaderCell
                              style={{
                                textTransform: "uppercase",
                                fontSize: "0.9em",
                              }}
                            >
                              {t("Commission percent %")}
                            </Table.HeaderCell>
                            <Table.HeaderCell
                              style={{
                                textTransform: "uppercase",
                                fontSize: "0.9em",
                              }}
                            >
                              {`${t("Net payout commission")} (zł)`}
                            </Table.HeaderCell>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {userPermmisions("Agency") && (
                            <TableRow>
                              <TableCell>
                                {t("Net agency commission")}
                              </TableCell>
                              <TableCell>
                                <TransactionInput
                                  amount
                                  disabled={true}
                                  defaultValue={parseFloat(
                                    data?.commission_agency_percentage
                                  )}
                                  control={control}
                                  name="commission_agency_percentage"
                                  type="text"
                                />
                              </TableCell>
                              <TableCell>
                                <TransactionInput
                                  amount
                                  disabled={true}
                                  defaultValue={regexStringToNumberPrice(
                                    data?.commission_agency_price_net
                                  )}
                                  control={control}
                                  name="commission_agency_price_net"
                                  type="text"
                                />
                              </TableCell>
                            </TableRow>
                          )}

                          {userPermmisions("Agency") &&
                            commissionPromotional?.promotional && (
                              <TableRow>
                                <TableCell colspan="3">
                                  {t("The promotional commission is valid")}
                                  {commissionPromotional?.from &&
                                    ` ${t("from")} ${
                                      commissionPromotional?.from
                                    }`}
                                  {commissionPromotional?.to &&
                                    ` ${t("to")} ${commissionPromotional?.to}`}
                                </TableCell>
                              </TableRow>
                            )}
                          {userPermmisions("Developer") && (
                            <TableRow>
                              <TableCell>
                                {t("Net commission")} Primse.com
                              </TableCell>
                              <TableCell>
                                <TransactionInput
                                  amount
                                  disabled={true}
                                  defaultValue={
                                    data?.commission_total_percentage
                                      ? parseFloat(
                                          data?.commission_total_percentage
                                        )
                                      : "0"
                                  }
                                  control={control}
                                  name="commission_total_percentage"
                                  type="text"
                                />
                              </TableCell>
                              <TableCell>
                                <TransactionInput
                                  amount
                                  disabled={true}
                                  defaultValue={regexStringToNumberPrice(
                                    data?.commission_total_price_net
                                  )}
                                  control={control}
                                  name="commission_total_price_net"
                                  type="text"
                                />
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </section>
                  </div>
                  {userPermmisions("Agency") && (
                    <>
                      {(data?.invoice_status_id === "0" ||
                        data?.invoice_document_uuid) && (
                        <div className="justify-around my-2 border-b-2 py-2">
                          <section
                            className="flex flex-col w-2/3"
                            style={{ marginLeft: "40px" }}
                          >
                            <h3 className="text-xl font-bold">
                              {t("Invoice for PRIMSE.COM from the agent")}
                            </h3>
                            {!data?.invoice_document_uuid &&
                              data?.invoice_status_id === "0" && (
                                <AddInvoiceModal
                                  watchForm={getWatch}
                                  uuid={uuid}
                                  role="agency"
                                  handleRefresh={handleRefresh}
                                />
                              )}
                            {data?.invoice_document_uuid && (
                              <Table
                                celled
                                structured
                                textAlign="center"
                                size="small"
                              >
                                <Table.Header>
                                  <Table.Row>
                                    <Table.HeaderCell
                                      className="w-1/6"
                                      style={{
                                        textTransform: "uppercase",
                                        fontSize: "0.9em",
                                      }}
                                    >
                                      {t("Invoice number")}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                      className="w-1/6"
                                      style={{
                                        textTransform: "uppercase",
                                        fontSize: "0.9em",
                                      }}
                                    >
                                      {t("Net amount")}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                      className="w-1/6"
                                      style={{
                                        textTransform: "uppercase",
                                        fontSize: "0.9em",
                                      }}
                                    >
                                      {t("VAT rate")}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                      className="w-1/6"
                                      style={{
                                        textTransform: "uppercase",
                                        fontSize: "0.9em",
                                      }}
                                    >
                                      {t("Date of invoice")}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                      className="w-1/6"
                                      style={{
                                        textTransform: "uppercase",
                                        fontSize: "0.9em",
                                      }}
                                    >
                                      {t("Status")}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                      className="w-1/6"
                                      style={{
                                        textTransform: "uppercase",
                                        fontSize: "0.9em",
                                      }}
                                    ></Table.HeaderCell>
                                  </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                  <Table.Row className=" hover:bg-primse-light-green">
                                    <Table.Cell>
                                      {data?.invoice_number}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {regexStringToNumberPrice(
                                        data?.invoice_price_net
                                      )}{" "}
                                      {data?.invoice_price_net && "PLN"}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {
                                        taxType.filter(
                                          (el) =>
                                            el.value ===
                                            data?.invoice_tax_type_id
                                        )[0].text
                                      }
                                    </Table.Cell>
                                    <Table.Cell>
                                      {data?.invoice_document_uuid &&
                                        data?.invoice_issued_at && (
                                          <Moment format="YYYY-MM-DD">
                                            {data?.invoice_issued_at}
                                          </Moment>
                                        )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      {getInvoicesStatuses?.agent}
                                    </Table.Cell>
                                    <Table.Cell className="font-bold">
                                      {data?.invoice_document_uuid && (
                                        <p
                                          className="cursor-pointer hover:text-primse-green"
                                          onClick={() =>
                                            DocumentsServices.getDocument({
                                              document_uuid:
                                                data?.invoice_document_uuid,
                                              file_name: data?.invoice_number
                                                ? `${data?.invoice_number}.pdf`
                                                : "FV.pdf",
                                            })
                                          }
                                        >
                                          {"Download"}
                                        </p>
                                      )}
                                    </Table.Cell>
                                  </Table.Row>
                                </Table.Body>
                              </Table>
                            )}
                          </section>
                        </div>
                      )}
                    </>
                  )}

                  {userPermmisions("Developer") &&
                    (data?.developer_invoice_status_id ||
                      data?.developer_invoice_document_uuid) && (
                      <div className="justify-around my-2 border-b-2 py-2">
                        <section
                          className="flex flex-col w-2/3"
                          style={{ marginLeft: "40px" }}
                        >
                          <h3 className="text-xl font-bold">
                            {t("Developer invoice")}
                          </h3>
                          {!data?.developer_invoice_document_uuid &&
                            data?.developer_invoice_status_id === "0" && (
                              <AddInvoiceModal
                                watchForm={getWatch}
                                uuid={uuid}
                                role="developer"
                                handleRefresh={handleRefresh}
                              />
                            )}
                          {data?.developer_invoice_document_uuid && (
                            <Table
                              celled
                              structured
                              textAlign="center"
                              size="small"
                            >
                              <Table.Header>
                                <Table.Row>
                                  <Table.HeaderCell
                                    className="w-1/6"
                                    style={{
                                      textTransform: "uppercase",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {t("Invoice number")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell
                                    className="w-1/6"
                                    style={{
                                      textTransform: "uppercase",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {t("Net amount")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell
                                    className="w-1/6"
                                    style={{
                                      textTransform: "uppercase",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {t("VAT rate")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell
                                    className="w-1/6"
                                    style={{
                                      textTransform: "uppercase",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {t("Date of invoice")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell
                                    className="w-1/6"
                                    style={{
                                      textTransform: "uppercase",
                                      fontSize: "0.9em",
                                    }}
                                  >
                                    {t("Status")}
                                  </Table.HeaderCell>
                                  <Table.HeaderCell
                                    className="w-1/6"
                                    style={{
                                      textTransform: "uppercase",
                                      fontSize: "0.9em",
                                    }}
                                  ></Table.HeaderCell>
                                </Table.Row>
                              </Table.Header>

                              <Table.Body>
                                <Table.Row className=" hover:bg-primse-light-green">
                                  <Table.Cell>
                                    {data?.developer_invoice_number}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {regexStringToNumberPrice(
                                      data?.developer_invoice_price_net
                                    )}{" "}
                                    {data?.developer_invoice_price_net && "PLN"}
                                  </Table.Cell>
                                  <Table.Cell>
                                    {
                                      taxType.filter(
                                        (el) =>
                                          el.value ===
                                          data?.developer_invoice_tax_type_id
                                      )[0].text
                                    }
                                  </Table.Cell>
                                  <Table.Cell>
                                    {
                                      <Moment format="YYYY-MM-DD">
                                        {data?.developer_invoice_issued_at}
                                      </Moment>
                                    }
                                  </Table.Cell>
                                  <Table.Cell>
                                    {getInvoicesStatuses?.developer}
                                  </Table.Cell>
                                  <Table.Cell className="font-bold">
                                    <p
                                      className="cursor-pointer hover:text-primse-green"
                                      onClick={() =>
                                        DocumentsServices.getDocument({
                                          document_uuid:
                                            data?.developer_invoice_document_uuid,
                                          file_name: data?.developer_invoice_number
                                            ? `${data?.developer_invoice_number}.pdf`
                                            : "FV.pdf",
                                        })
                                      }
                                    >
                                      {"Download"}
                                    </p>
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          )}
                        </section>
                      </div>
                    )}
                </div>
              </main>
            </Form>
          </>
        )}
        {isLoading && <Loader />}
      </Container>
    </main>
  );
};

export default Transaction;
