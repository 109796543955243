import { useStoreState } from "easy-peasy";
import { Route, Redirect } from "react-router";
import Unauthorized from "../Unauthorized";
import useUserRole from "../../../hooks/useUserRole";

const PrivateRoute = (props) => {
  const isLoggedIn = useStoreState((state) => state.auth.isLoggedIn);
  const userRole = useStoreState((state) => state.auth.user?.role);

  const { isManager } = useUserRole();

  if (!isLoggedIn) {
    return <Unauthorized />;
  }

  if (!isManager() && props.allowedRole === "manager") {
    return <Redirect to="/" />;
  }

  if (userRole === "Deweloper") {
    if (
      props.path === "/" ||
      props.path === "/client/:uuid" ||
      props.path === "/clients"
    ) {
      return (
        <Route {...props}>
          <Redirect to="/requests" />
        </Route>
      );
    }
  }

  return <Route {...props} />;
};
export default PrivateRoute;
