import { action } from "easy-peasy";

const filtersStore = {
  clientFilter: null,
  clientFilterAssignedTo: [],
  requestFilter: null,
  requestFiltersSugestions: {},
  searchFilters: null,
  userAgencySupervisorsFilter: null,

  setClientFilter: action((state, payload) => {
    state.clientFilter = payload;
  }),
  setClientAssisgnedTo: action((state, payload) => {
    state.clientFilterAssignedTo = payload;
  }),
  setRequestFilter: action((state, payload) => {
    state.requestFilter = payload;
  }),
  setRequestFiltersSugestions: action((state, payload) => {
    state.requestFiltersSugestions = payload;
  }),

  setSearchFilters: action((state, payload) => {
    state.searchFilters = payload;
  }),

  setUserAgencySupervisorsFilter: action((state, payload) => {
    state.userAgencySupervisorsFilter = payload;
  }),

  cleanAllFilters: action((state) => {
    state.clientFilter = null;
    state.clientFilterAssignedTo = [];
    state.requestFilter = null;
    state.requestFiltersSugestions = {};
    state.investmentsFilters = null;
    state.realestatesFilters = null;
    state.searchFilters = null;
    state.userAgencySupervisorsFilter = null;
  }),
};

export default filtersStore;
